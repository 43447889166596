import React from "react"
import checkIcon from "../images/check-mark.png"
import { graphql, Link } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import css from "@emotion/css"
import Seo from "../components/Seo"
export default function ProductTemplate({ data: { page } }) {
  const options = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="flex text-left items-start my-3">
          {console.log(node, children)}
          <img src={checkIcon} className="w-4 mt-1 mr-4 h-4"></img>
          {node.content[0].content[0].value}
        </li>
      ),
    },
  }
  const applyOptions = {
    renderNode: {
      // [BLOCKS.PARAGRAPH]: (node, children) => (
      //   <p className=" text-sm mb-3">{children}</p>
      // ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="font-bold text-base mt-3">{children}</h6>
      ),
    },
  }
  return (
    <>
      <Seo
        data={{
          title: page.seo && page.seo.title,
          description: page.seo && page.seo.description,
        }}
      ></Seo>
      <div className="container py-6">
        <h2 className="text-3xl font-semibold text-center">{page.title}</h2>
      </div>
      <div className="bg-brand-2 text-white py-6">
        <div className="container md:flex justify-between">
          {page.limits && (
            <div className="text-center my-3 md:my-0 md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">Limits</h3>
              <p className="text-xl text-brand-light">{page.limits}</p>
            </div>
          )}
          {page.terms && (
            <div className="text-center my-3 md:my-0 md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">Terms</h3>
              <p className="text-xl text-brand-light">{page.terms}</p>
            </div>
          )}
          {page.factorRates && (
            <div className="text-center my-3 md:my-0 md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">Factor Rates</h3>
              <p className="text-xl text-brand-light">{page.factorRates}</p>
            </div>
          )}
          {page.rates && (
            <div className="text-center my-3 md:my-0 md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">RATES</h3>
              <p className="text-xl text-brand-light">{page.rates}</p>
            </div>
          )}
          {page.quickness && (
            <div className="text-center my-3 md:my-0 md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">QUICKNESS</h3>
              <p className="text-xl text-brand-light">{page.quickness}</p>
            </div>
          )}
        </div>
      </div>
      <div className="container py-8">
        <div className="clearfix">
          <div className="px-10 py-8 md:ml-6 w-full mb-4 md:w-1/3 flex flex-col justify-between text-white bg-blue-800 md:float-right text-center features-sidebar">
            {page.features &&
              documentToReactComponents(page.features.json, options)}
            {/* <div className="text-center my-1  md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">ANNUAL REVENUE</h3>
              <p className="text-xl text-brand-light">$75,000+</p>
            </div>
            <div className="text-center my-1  md:block font-semibold ">
              <h3 className="text-2xl font-bold uppercase">BUSINESS AGE</h3>
              <p className="text-xl text-brand-light">3 Months+</p>
            </div> */}
          </div>
          <div id="content">
            {page.content && documentToReactComponents(page.content.json)}
            {page.hasIconListInContent && page.iconList && (
              <ul className="items-with-icons">
                {page.iconList.map((icon, i) => {
                  return (
                    <li
                      key={i}
                      css={css`
                        &:after {
                          content: "";
                        }
                      `}
                    >
                      <img src={icon.icon.file.url} className="mr-3 "></img>
                      {icon.text}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
      {page.prosAndConsSection && (
        <div className="bg-brand-gray">
          <div className="container py-12 flex flex-col md:flex-row">
            <div className="w-full md:w-1/2   flex flex-col md:flex-row items-start md:items-center">
              {" "}
              <h2 className="text-brand-2 text-3xl uppercase font-bold w-40">
                Pros
              </h2>
              <ul className="text-brand-4 font-bold text-brand-dark">
                {page.pros && page.pros.map(item => <li key={item}>{item}</li>)}
              </ul>
            </div>
            <div className="w-full md:w-1/2  mt-10 md:mt-0 md:pl-8 flex flex-col md:flex-row items-start md:items-center ">
              {" "}
              <h2 className="text-brand-2 text-3xl uppercase font-bold w-40">
                Cons
              </h2>
              <ul className="text-brand-4 font-bold text-brand-dark">
                {page.cons && page.cons.map(item => <li key={item}>{item}</li>)}
              </ul>
            </div>
          </div>
        </div>
      )}
      {page.howToApplySection && (
        <div className="bg-brand-gray mt-4">
          <div className="container py-12 flex flex-col lg:flex-row">
            <div id="applySection">
              <h2 className="font-bold text-4xl text-brand-2 mb-3">
                How to apply..
              </h2>
              {documentToReactComponents(page.howToApply.json, applyOptions)}{" "}
            </div>
            <div className="   mt-8 lg:mt-0  text-white w-full md:w-1/2 ml-0 lg:ml-6 flex items-center lg:justify-center">
              <Link
                to="/apply/"
                css={css`
                  background: rgba(23, 98, 167, 0.9);
                `}
                className="text-2xl md:text-3xl py-3    px-10 shadow-lg  bg-brand-1 text-white uppercase font-bold "
              >
                Apply Now
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    page: contentfulProduct(slug: { eq: $slug }) {
      title
      slug
      content {
        json
      }
      hasApplyButtonInContent
      hasIconListInContent
      howToApplySection
      howToApply {
        json
      }
      iconList {
        text
        icon {
          file {
            url
          }
        }
      }
      seo {
        title
        description
      }
      limits
      name
      pros
      prosAndConsSection
      quickness
      rates
      terms
      cons
      factorRates
      description {
        json
      }
      features {
        json
      }
    }
  }
`
